<template>
  <div class="row mt5 mb5">
    <div class="col-4 m-auto">
      <div class="details-card details-card--green text-center">
        <span class="card_title d-block"> Account State </span>
        <span class="card_subtitle d-block">
          {{ accountDetails.accountStatus }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    accountDetails: [Array, Object],
  },
};
</script>
